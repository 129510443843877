.Table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Table.refreshing .head,
.Table.refreshing .body .item {
  color: transparent!important;
  background-image: linear-gradient(to right, #e7ebee, #fff) !important;
  transition: .2s ease-in-out;
}
.Table.refreshing span,
.Table.refreshing svg {
  display: none!important;
}
.Table .head,
.Table .body .item {
  display: grid;
  grid-template-columns: 120px repeat(5, 1fr);
  width: 100%;
  grid-gap: 10px;
  border-radius: 6px;
  transition: all 0.78s cubic-bezier(.71,0,.33,1.56) 0ms;
  min-height: 50px;
}

.Table._cart-list {
  margin: 0 0 30px;
}
.Table._cart-list .head,
.Table._cart-list .body .item {
  grid-template-columns: 2fr repeat(3, 1fr)!important;
}

.Table.Products {
  margin: 0 0 30px;
}
.Table.Products .body .item {
  position: relative;
}
.Table.Products .head,
.Table.Products .body .item {
  grid-template-columns: 24px 3fr repeat(3, 1fr);
}
.Table.Products .body .item span,
.Table.Upsells .body .item span {
  max-width: calc(100% - 30px);
}

.Table.Clients {
  margin: 0 0 30px;
}
.Table.Clients .head,
.Table.Clients .body .item {
  grid-template-columns: 2fr 2fr 1fr 1fr;
}
.Table.Clients .body .item span {
  max-width: 300px;
}

.Table.Categories {
  margin: 0 0 30px;
}
.Table.Categories .head,
.Table.Categories .body .item {
  grid-template-columns: repeat(3, 1fr);
}
.Table.productsCategory .head,
.Table.productsCategory .body .item {
  grid-template-columns: 1fr 1fr;
}
.Table.productsUpsell .head,
.Table.productsUpsell .body .item {
  grid-template-columns: 2fr 1fr 1fr;
}

.Table.Upsells {
  margin: 0 0 30px;
}
.Table.Upsells .head,
.Table.Upsells .body .item {
  grid-template-columns: repeat(2, 1fr);
}

.Table .head {
  justify-content: space-between;
  padding: 16px 26px;
  background: var(--gray-dark);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin: 0 0 10px;
  position: relative;
  overflow: hidden;
}
.Table.refreshing .head {
  margin: 0 0 5px;
}
.Table .head .refreshButton {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 10px;
  background: var(--gray-darkother);
  border: none;
  width: 60px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: width 0.3s cubic-bezier(.71,0,.33,1.56) 0ms;
}
.Table.refreshing .head .refreshButton {
  display: none!important;
}
.Table.refreshing .body .item .status span {
  background: transparent!important;
  border: none!important;
  color: transparent!important;
}
.Table.refreshing .body .item.accept,
.Table.refreshing .body .item.canceled {
  border: none!important;
}
.Table .head .refreshButton:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  
  color: #fff;
}
.Table .head .refreshButton:hover i {
  transform: rotate(360deg);
  transition: .4s ease-in-out;
}
.Table .body {
  flex-direction: column;
  width: 100%;
}
.Table .body .item {
  padding: 8px 8px 8px 20px;
  margin: 0 0 5px;
  align-items: center;
  grid-template-columns: .1fr 120px repeat(5, 1fr);
  
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  background: white;
  color: var(--gray-five);
  
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
}
.Table._cart-list .body .item {
  padding: 14px 14px 14px 18px;
}
.Table .body .item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.Table .body .item .free {
  background: #73bd5e;
  font-size: 14px;
  font-weight: 800;
  color: white;
  padding: 8px 10px;
  margin: -15px 0 0;
  position: absolute;
  border-radius: 6px;
}
.Table .body .status {
  display: flex;
  justify-content: flex-end;
}
.Table .body .item .status span {
  background: #b9b9b9;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  width: max-content;
  border-radius: 4px;
  font-weight: 800;
}
.Table .body .item .status .payAprovado {
  background-color: #94ca85;
}
.Table .body .item .status .payCancelado {
  background-color: var(--lilacother);
}
.Table .body .item .status .payNegado {
  background-color: #caac85;
}
.Table .body .item .status .payPixPendente,
.Table .body .item .status .payPixAgendado,
.Table .body .item .status .payAguardandoPix,
.Table .body .item .status .payAguardando {
  background-color: #8586ca;
}

.Table .body .item .price {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
.Table .body .item .price span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 800;
  color: #5b6073;
}
.Table .body .item .price span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 800;
  color: var(--green-oneother);
}

.paymentInfo {
  display: grid;
  grid-template-columns: 26px 1fr;
  grid-gap: 10px;
}
.paymentInfo.cancelled .price span,
.paymentInfo.cancelled .paymentMethod {
  color: rgba(0,0,0,.2)!important;
}
.paymentInfo .paymentMethod {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5b6073;
  font-size: 22px;
  font-weight: 800;
}
.paymentInfo .paymentMethod.PIX {
  font-size: 14px;
  font-weight: 900;
  color: #5b6073;
}
.paymentInfo .price {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
.paymentInfo .price span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 800;
  color: #5b6073;
}
.paymentInfo .price span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 800;
  color: var(--green-oneother);
}

.Table .body .item.accept,
.Table .body .item.canceled {
  border: 2px dashed!important;
}
.Table .body .item.accept {
  border-color: #94ca85!important;
  background-color: rgba(148, 202, 133, 0.06)!important;
}
.Table .body .item.canceled {
  border-color: rgba(200,0,0,.3)!important;
  background-color: rgba(202, 148, 133, 0.06)!important;
}
.Table .body .item.novo.accept .newMessage {
  background-color: #94ca85!important;
}

.Table._cart-list .body .item {
  border: 1px dashed var(--green-oneother);
}
.Table .body .item:hover {
  transform: scale(1.03);
}
.Table .body .item:nth-child(2n),
.Table .body .item:nth-child(2n) {
  background-color: #f2f2f2;
}
.Table .body .item span.newMessage {
  font-size: 12px;
  background: #8789e7;
  border-radius: 4px;
  color: #e7ebee;
  overflow: inherit!important;
  padding: 4px 6.5px;
  font-weight: 700;
  text-align: center;
  width: max-content;
}
.Table .body .item.novo span.newMessage {
  padding: 4px 8px;
  background: #aeb98f;
}

.Table .body .item.manual {
  background: transparent;
  font-weight: 500;
  color: var(--gray-five);
  border: 2px solid #fff;
  grid-template-columns: .1fr 120px repeat(5, 1fr);
}
.Table .body .item.manual span.newMessage {
  font-size: 12px;
  background: #32ade8;
  padding: 4px 8px;
  border-radius: 4px;
  color: #e7ebee;
  overflow: inherit!important;
}
.Table .body .item.manual .payAguardando {
  background: #32ade8!important;
}

.DynamicTable {
  cursor: pointer;
}
.DynamicTable .head {
  grid-template-columns: 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr;
  grid-gap: 20px;
}
.DynamicTable.Orders .head {
  grid-template-columns: 185px 2fr repeat(3, 1fr) 1.4fr;
  grid-gap: 10px;
  padding: 16px 8px 16px 20px;
}
.DynamicTable.Orders .body .item {
  grid-template-columns: 56px 120px 2fr repeat(3, 1fr) 1.4fr;
  grid-gap: 10px;
}


.DynamicTable.OrdersClient .order-content {
  min-width: 730px;
  max-width: 730px;
}
.DynamicTable.OrdersClient .head {
  grid-template-columns: 195px repeat(3, 1fr);
}
.DynamicTable.OrdersClient .body .item {
  grid-template-columns: 60px 140px repeat(3, 1fr);
}

.SectionDelivery .DynamicTable .head {
  grid-template-columns: 170px 2fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.SectionDelivery .DynamicTable .body .item {
  padding: 16px 24px;
  grid-template-columns: 170px 2fr 1fr 1fr 1fr;
}
.SectionDelivery .DynamicTable .body .item.novo {
  padding: 16px 24px;
  position: relative;
}
.SectionDelivery .DynamicTable .body .item > div {
  display: flex;
  align-items: center;
}
.SectionDelivery .DynamicTable .body .item.novo span.newMessage {
  font-size: 12px;
  background: #aeb98f;
  border-radius: 4px;
  color: #e7ebee;
  display: inline-flex;
  width: max-content;
  margin: 0;
  padding: 4px 8px;
  margin: 0 4px 0 0;
}

.DynamicTable .body .itemDelivery {
  grid-template-columns: 1.5fr 2fr 1.5fr 1fr 1.5fr;
  grid-gap: 20px;
}
.DynamicTable .body .itemDelivery:nth-child(2n) .item {
  background-color: #f2f2f2;
}
.DynamicTable .itemDelivery.active {
  margin: 0 0 5px;
  border-radius: 10px;
}
.DynamicTable .itemDelivery.active > .item {
  border: none;
}
.DynamicTable.refreshing .itemDelivery.active .item {
  margin: 0!important;
}
.DynamicTable .itemDelivery.active > .item:hover {
  transform: none!important;
}
.DynamicTable .content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 10px;
}
.DynamicTable .content .details {
  grid-gap: 40px;
  display: flex;
  width: 100%;
}
.DynamicTable .content .details .item_info {
  display: flex;
  flex-direction: column;
}
.DynamicTable .content .details .item_info .title {
  font-size: 16px;
  color: var(--green-oneother);
  line-height: 1.1;
  text-transform: uppercase;
}
.DynamicTable .content .details .item_info .text {
  font-size: 18px;
  color: var(--gray-dark);
  font-weight: 700;
}
.DynamicTable .content .buttonactions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Table .coupon-item {
  width: 100%;
  margin: -30px auto 0;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 800;
  border-radius: 8px;
  color: #73bd5e;
  border: 2px dashed;
  text-align: center;
  background: #73bd5e2e;
}