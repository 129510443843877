:root {
  --background-modal: rgb(116 116 116 / 82%);
}

.GoogleApplications {
  width: 100%;
}

.GoogleApplications h2 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 500;
  display: flex;
  align-items: center;
}
.GoogleApplications h2 i {
  margin: 0 20px 0 0;
  font-size: 30px;
}

.GoogleApplications .menu {
  height: 67px;
  margin: 40px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.GoogleApplications .menu button {
  height: 100%;
  width: 100%;
  border: none;
  background: #686f79;
  color: white;
  cursor: pointer;
  border-radius: 16px;

  font-size: 18px;
  font-weight: 600;
}
.GoogleApplications .menu button i {
  margin: 0 10px 0 0;
}
.GoogleApplications .menu button.active,
.GoogleApplications .menu button:hover {
  background: #35393e;
}

.GoogleApplications .section-actions {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
}
.GoogleApplications .section-actions button {
  height: 100%;
  background: rgb(147, 166, 117);
  color: white;
  padding: 15px 28px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;

  display: flex;
  align-items: center;
}
.GoogleApplications .section-actions button:hover {
  background: rgb(112, 130, 83);
}
.GoogleApplications .section-actions button i {
  margin: 0 10px 0 0;
}

.GoogleApplications .MerchantCenter {
  margin: 60px 0 0;
}
.GoogleApplications .MerchantCenter .head {
  display: flex;
  align-items: center;
}
.GoogleApplications .MerchantCenter .head .actions {
  margin: 0 0 0 40px;
}
.GoogleApplications .MerchantCenter .actions button {
  border: none;
  width: max-content;
  padding: 20px 20px 20px 30px;
  background: rgb(95, 189, 243);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
}
.GoogleApplications .MerchantCenter .actions button:hover {
  background: rgb(47, 174, 248);
}
.GoogleApplications .MerchantCenter .actions button i {
  margin: 0 0 0 10px;
}
.GoogleApplications .MerchantCenter .button-float {
  padding: 16px 40px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  background: #b2c09d;
  color: white;
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 200px;
  z-index: 3;
  cursor: pointer;
}
.GoogleApplications .products {
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.GoogleApplications .products .product {
  background: white;
  border-radius: 12px;
  cursor: pointer;
  border: 3px solid transparent;
  overflow: hidden;

  position: relative;
  opacity: 1!important;
}
.GoogleApplications .products .product.disabled .picture,
.GoogleApplications .products .product.disabled .details > h4 {
  opacity: 0.4;
}
.GoogleApplications .products .product:hover {
  border-color: rgba(147, 166, 117,.5);
}
.GoogleApplications .products .product:hover .checkbox {
  border-color: rgba(147, 166, 117,.5);
}
.GoogleApplications .products .product .checkbox {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,.2);

  position: absolute;
  top: 20px;
  right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.GoogleApplications .products .product .checkbox.sync {
  background: rgb(95, 189, 243)!important;
  border-color: transparent!important;
  width: auto;
  height: auto;
  border-radius: 12px;
  padding: 8px 20px;
}
.GoogleApplications .products .product .checkbox.sync i {
  color: white!important;
  font-size: 14px;
  margin: -3px 6px 0 0;
}
.GoogleApplications .products .product .checkbox.sync p {
  margin: 0;
  color: white;
  font-weight: 600;
}
.GoogleApplications .products .product .checkbox:hover,
.GoogleApplications .products .product .checkbox.active {
  border-color: rgba(147, 166, 117, .5);
  background: rgba(147, 166, 117,.7);
}
.GoogleApplications .products .product .checkbox:hover i,
.GoogleApplications .products .product .checkbox.active i {
  color: white;
}
.GoogleApplications .products .product .checkbox i {
  color: transparent;
  font-size: 22px;
  padding: 1px 1px 0 0;
}
.GoogleApplications .products .product .picture {
  min-height: 260px;
  max-height: 260px;
  background-size: cover;
  background-position: center;
}
.GoogleApplications .products .product .details {
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
