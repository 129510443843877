.button {
  padding: 8px;
  margin: -15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  border: none;
  border-radius: 6px;
  background: #8586ca;
  color: #fff;
  cursor: pointer;
}