@media (max-width: 1400px) {
  .dashboard {
    padding: 62px 0 0;
  }
  .dashboard > .menu {
    padding: 10px 60px;
    height: 62px;
  }
  .dashboard > .menu .content .menu-items .item,
  .dashboard > .menu .content .button-power {
    width: 42px;
    height: 42px;
  }

  .loading svg {
    height: 30px;
  }
}

@media (max-width: 768px) {
  .dashboard > .menu {
    padding: 10px 20px;
  }
  .TemplateBody {
    padding: 40px 20px 300px;
  }
}