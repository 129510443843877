.order-view {
  width: 100%;
  height: 0;
  
  display: flex;
  justify-content: space-between;
  transition: .4s ease;
  
  position: relative;
}
.order-view.active {
  height: inherit;
  padding: 30px 30px 60px;
}

.order-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.order-content {
  display: flex;
  flex-direction: column;
  padding: 60px;
  background: white;
  border-radius: 20px;
  border: 2px dashed rgba(0,0,0,.3);
  min-width: 800px;
}
.order-view.refreshing {
  color: transparent!important;
  background-image: linear-gradient(to right, #e7ebee, #fff) !important;
  transition: all 0.78s cubic-bezier(.71,0,.33,1.56) 0ms;
}
.order-view.refreshing .order-content h1,
.order-view.refreshing .order-content p,
.order-view.refreshing .order-content span,
.order-view.refreshing .order-content label,
.order-view.refreshing .order-content h2,
.order-view.refreshing .order-content h3,
.order-view.refreshing .order-content h4,
.order-view.refreshing .order-content h5,
.order-view.refreshing .order-content input,
.order-view.refreshing .order-content select,
.order-view.refreshing .order-content button,
.order-view.refreshing .order-content svg,
.order-view.refreshing .order-content .paymentMethod {
  background: #e8e8e8!important;
  color: transparent!important;
  line-height: 1;
  margin: 0 0 10px;
  border-radius: 50px;
}
.order-view.refreshing .button-actions * {
  color: transparent!important;
  opacity: .5;
}
.order-content.cancelled {
  background: #f8eeee;
  border: 2px dashed rgb(250 176 176);
}
.order-content .order-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order-content .order-section .head-info {
  display: flex;
  justify-content: space-between;
}
.order-content .order-section .head-info .card-info {
  padding: 10px 20px;
  background-color: #b2b2b2;
  color: white;
  font-size: 16px;
  border-radius: 8px;
}
.order-content .order-section .head-info .card-info.added {
  background: #69c871;
}
.order-content .order-section .head-info .cancelled {
  padding: 10px 20px;
  background-color: #f76b6b;
  color: white;
  font-size: 16px;
  border-radius: 8px;
}
.order-content label {
  font-size: 16px;
}
.order-content label span {
  font-weight: 800;
}
.order-content .list-items {
  margin: 40px 0 0;
}
.order-content .list-items .info {
  margin: 0 0 10px;
}
.order-content .list-items .info h3 {
  font-size: 20px;
  margin: 0 10px 0 0;
  font-weight: 700;
}
.order-content .list-items .info span {
  font-size: 18px;
}
.order-content .list-items .line {
  height: 30px;
}
.order-content .list-items .values {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.order-content .list-items .values .paymentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
}
.order-content .list-items .values .paymentInfo .paymentMethod {
  display: flex;
  flex-direction: column;
}
.order-content .list-items .values .paymentInfo .paymentMethod span {
  font-size: 18px;
  font-weight: 800;
}
.order-content .list-items .values .paymentInfo .paymentMethod svg {
  height: 36px;
  width: auto;
  margin: 0 0 4px;
}
.order-content .list-items .values .paymentInfo .paymentMethod.paymentMethod.PIX {
  font-size: 20px;
}
.order-content .list-items .values .value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-content .list-items .values .value span {
  font-size: 14px;
  font-weight: 800;
  margin: 2px 0 0; 
}
.order-content .list-items .values .value h4 {
  font-size: 20px;
  line-height: 1;
  margin: 0 0 5px;
}
.order-content .list-items .values .value h5 {
  font-size: 22px;
  font-weight: 800;
  line-height: 1;
}
.order-content .list-items .values .value:nth-of-type(3) h5 {
  color: rgb(114 188 93);
}
.order-content .list-items .values .value:nth-of-type(2) h5,
.order-content .list-items .values .value:nth-of-type(1) h5 {
  color: rgb(126, 126, 126);
}

.order-content .button-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 40px;
}

.order-content .order-section .status-container {
  margin: 60px 0 0;
}
.order-content .order-section .status-container h2 {
  margin: 0 0 20px;
  text-align: center;
}
.order-content .order-section .status-container .status-list .status-item {
  padding: 10px 20px;
  margin: 0 0 2px;
  border-radius: 8px;
  border: 2px solid white;
  color: #585858;
  background: #ededed;
  border-left: 6px solid var(--green-two);

  display: flex;
  flex-direction: column;
}
.order-content .order-section .status-container .status-list .status-item h3 {
  font-size: 18px;
  font-weight: 700;
}
.order-content .order-section .status-container .status-list .status-item p.justify {
  font-size: 16px;
  font-weight: 600;
  margin: 2px 0 0;
}
.order-content .order-section .status-container .selector {
  display: flex;
  width: 100%;
  height: 48px;
  margin: 10px 0;
}
.order-content .order-section .status-container .selector select {
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 8px;
  padding: 0 20px;
  cursor: pointer;
  font-family: 'Nunito';
  font-weight: 600;
  background-color: #ededed;
  height: 48px;
}
.order-content .order-section .status-container .selector button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin-left: 5px;
  color: white;
  border-radius: 8px;
  background: var(--green-two);
  transition: .4s;
  height: 48px;
}
.order-content .order-section .status-container .selector button:hover {
  background: var(--green-three);
}
.order-content .order-section .status-container .status-list .input-justify {
  width: 100%;
  padding: 14px 20px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid var(--gray-two);
}

.order-wrapper .button-actions {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.order-wrapper .button-actions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 24px;
  width: 60px;
  height: 60px;
  margin: 0 0 10px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  
  transition: .4s;
}
.order-wrapper .button-actions > button:hover {
  transform: scale(1.1)
}
.order-wrapper .button-actions > button.whatsapp {
  background-color: rgb(54, 197, 49);
  color: white;
}
.order-wrapper .button-actions > button.view-card {
  background-color: rgb(223, 162, 105);
  color: white;
}
.order-wrapper .button-actions > button.view-order {
  background-color: rgb(105, 170, 223);
  color: white;
}
.order-wrapper .button-actions > button.seen {
  background-color: rgb(160, 105, 223);
  color: white;
}
.order-wrapper .button-actions > button.cancel {
  background-color: rgb(223, 105, 105);
  color: white;
}
.order-wrapper .button-actions > button.confirm {
  background-color: rgb(105, 223, 115);
  color: white;
}