@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('./_table.css');
@import url('./_modal.css');
@import url('./_inputs.css');
@import url('./_buttons.css');
@import url('./order-view.css');
@import url('./reports.css');
@import url('./email-marketing.css');
@import url('./google-applications.css');
@import url('./discount.css');

:root {
  --xleg-orange: #ff6a00;
  
  --green: rgb(17, 131, 17);
  --red: #bf4d55;
  --redother: #b0323a;

  --black: #000;
  --white: #fff;
  --gray-light: #e3e3e3;
  --gray-dark: #495057;
  --gray-darkother: #35393e;
  --gray-medium: #888;

  --gray-one: #e7ebee;
  --gray-two: #dfe0e2;
  --gray-three: #c1c2c4;
  --gray-four: #a4a4ac;
  --gray-five: #7a7b7d;
  --green-one: #aeb98f;
  --green-oneother: #9eab7c;
  --green-two: #89c600;
  --green-three: #75a500;
  --brown: #482813;
  --lilac: #ca858a;
  --lilacother: #bf7a7f;

  --purple-one: #90a1e3;
  --purple-onehover: #7889ce;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7ebee;
  width: 100%;
  height: 100%;
  color: var(--gray-dark);
}

a, button, body, input, textarea {
  font-family: 'Nunito', sans-serif;
}

a, button, input, i {
  outline: none!important;
  text-decoration: none!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  box-shadow: none!important;
  -moz-shadow: none!important;
  -o-shadow: none!important;
  -webkit-box-shadow: none!important;
  background: transparent!important;
  outline: none!important;
  -webkit-text-fill-color: rgba(0,0,0,.7)!important;
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  width: 16px;
  background: rgb(241,241,239);
  padding: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadce0;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,.5);
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  min-height: 50px;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

p {
  margin: 5px 0 10px;
}

h4 {
  margin: 0 0 10px;
}

.full-width { width: 100%; }
.full-height { height: 100%; }

/* FLEX */
.flex { display: flex; }
.horizontal { flex-direction: row; }
.vertical { flex-direction: column; }

.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }
.flex-5 { flex: 5; }
.flex-6 { flex: 6; }
.flex-7 { flex: 7; }
.flex-8 { flex: 8; }

.flex-start,
.flex-end,
.flex-center {
  align-items: center;
}
.flex-start { justify-content: flex-start; }
.flex-end { justify-content: flex-end; }
.flex-center { justify-content: center; }


/*
** Components HOC
*/

/* MENU */
.dashboard {
  padding: 80px 0 0;
  overflow: hidden;
}
.dashboard > .menu {
  background-color: rgb(53 57 62 / 90%);
  height: 80px;
  padding: 15px 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
  backdrop-filter: blur(7px);
}
.dashboard > .menu .content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.dashboard > .menu .content .logo {
  display: flex;
  align-items: center;
}
.dashboard > .menu .content .logo svg {
  padding: 4px 0 0;
  height: 100%;
}
.dashboard > .menu .content .logo span {
  margin: 0 0 0 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.dashboard > .menu .content .menu-items {
  display: flex;
}
.dashboard > .menu .content .menu-items .item {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  border-radius: 12px;
  background: transparent;
  border: 2px solid rgb(112, 130, 83);
  position: relative;
}
.dashboard > .menu .content .menu-items .item:hover,
.dashboard > .menu .content .menu-items .item.active {
  background: rgb(112, 130, 83);
  border-color: transparent;
}
.dashboard > .menu .content .menu-items .item .icon {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  transition: .08s linear;
}
.dashboard > .menu .content .menu-items .item:hover .icon,
.dashboard > .menu .content .menu-items .item.active .icon {
  transform: scale(1.1);
}
.dashboard > .menu .content .menu-items .item .icon span.new {
  font-size: 10px;
  font-weight: 700;
  background: #499933;
  padding: 2px 4px;
  border-radius: 4px;
  position: absolute;
  top: -5px;
  right: -5px;
}
.dashboard > .menu .content .menu-items .item .title {
  height: 0!important;
  opacity: 0;
  padding: 0;
  background: rgba(114, 139, 80, 0.8);
  backdrop-filter: blur(20px);
  font-size: 18px;
  color: transparent;
  position: absolute;
  bottom: -56px;
  left: -10px;
  text-align: center;
  border-radius: 10px;
  width: max-content;
  
  transition: opacity .4s ease-in-out;
  transition-delay: 1s;
  overflow: hidden;
}
.dashboard > .menu .content .menu-items .item .icon:hover ~ .title {
  color: white;
  height: auto!important;
  opacity: 1;
  padding: 6px 12px;
}
.dashboard > .menu .content .button-power {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  border-radius: 12px;
  background: rgba(0,0,0,.2);
  cursor: pointer;
  background: transparent;
  border: 2px solid  rgb(211, 80, 80);
  opacity: .6;
}
.dashboard > .menu .content .button-power:hover {
  background:  rgb(211, 80, 80);
  border-color: transparent;
  opacity: 1;
}
.dashboard > .menu .content .button-power i {
  color:  rgb(211, 80, 80);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  transition: .08s linear;
  position: relative;
}
.dashboard > .menu .content .button-power:hover i,
.dashboard > .menu .content .button-power.active i {
  color: white;
  transform: scale(1.1);
}

.TemplateBody {
  display: flex;
  flex-direction: column;
  transition: all .5s;
  width: 100%;
  padding: 60px 60px 200px;

  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.TemplateBody .actionsTollBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  margin: 20px 0;
}

.content-box {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.container-centered { width: 100%; max-width: 800px; margin: 0 auto; }
.container-centered > .head { margin: 0 0 40px; display: flex; justify-content: space-between; }

.content-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
.content-tools .tools {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  padding: 40px;
  background: #fff;
  border-radius: 20px;
  margin: 0 0 40px;
}
.Titulo-primario {
  line-height: 1;
  margin: 0 0 20px;
  font-size: 40px;
  font-weight: 500;
  color: var(--green-oneother);
}
.btntool {
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 24px;
  width: 60px;
  height: 60px;
  margin: 5px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  
  transition: .4s;
}
.btntool:hover {
  
}
.btntool.whatsapp {
  background-color: rgb(54, 197, 49);
  color: white;
}
.btntool.view-card {
  background-color: rgb(223, 162, 105);
  color: white;
}
.btntool.view-order {
  background-color: rgb(105, 170, 223);
  color: white;
}
.btntool.seen {
  background-color: rgb(160, 105, 223);
  color: white;
}
.btntool.cancel {
  background-color: rgb(223, 105, 105);
  color: white;
}
.btntool.confirm {
  background-color: rgb(105, 223, 115);
  color: white;
}
.btntool.copy {
  background: #69c4df;
  color: white;
}

.btntool span {
  opacity: 0;
  width: 0;
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 900;
  margin: 0;
}
.btntool:hover span {
  margin: 0 0 0 16px;
  opacity: 1;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  line-height: 1;
  transition: .2s ease-in-out, opacity 2s;
  transition-delay: .2s;
}

.btntool-default {
  background: var(--gray-two);
  color: var(--gray-five);
  border-color: rgba(0,0,0,.02);
}

.btntool-danger {
  background: var(--lilac);
  color: #fff;
  border-color: var(--lilacother);
}
.btntool-success {
  background: var(--green-one);
  color: #fff;
  border-color: var(--green-oneother);
}
.btntool-blue {
  background: #2cc6df;
  color: #fff;
  border-color: #2cb7ce
}
.btntool-orange {
  background: #d97b40;
  color: #fff;
  border-color: #f0a573;
}
.btntool-default.max:hover,
.btntool-danger:hover,
.btntool-success:hover,
.btntool-blue:hover,
.btntool-orange:hover {
  width: max-content;
  padding: 0 20px;
}
.DynamicTable .itemDelivery a.viewClient {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 700;
  background: #878282;
  color: white;
  margin: 0 10px;
  border-radius: 6px;
  opacity: .8;
  transition: .4s ease-in-out;
}
.DynamicTable .itemDelivery a.viewClient:hover {
  opacity: 1;
}
.DynamicTable.OrdersClient a.viewClient { display: none; }

.destaque-one h4 {
  font-size: 20px;
  margin: 0;
  color: var(--gray-dark);
}

/* PAGE TITLE */
.Page-Title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 10px;
}
.Page-Title h1 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 500;
  margin: 0 0 10px;
}
.Product .Page-Title h1 {
  margin: 0 140px 10px 0;
}
.Page-Title .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.Produtos .Page-Title {
  margin: 0 0 10px;
}

/* CARDS */
.Card, .Sub-Card, .boxDetalhes {
  border-radius: 10px;
  overflow: hidden;
  background: var(--white);
  padding: 40px;
}
.Sub-Card:first-child {
  margin: 0 20px 0 0;
}
.Card-Table {
  border-radius: 10px;
  overflow: hidden;
  background: var(--white);
  margin-bottom: 30px;
}
.Card-Table .Subtitulo {
  padding: 20px;
  font-size: 22px;
  margin: 0;
  background: var(--lilacother);
  color: #fff;
  font-weight: 800;
}

.TableMaxima {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

.Grid-2 {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  min-width: 100%;
}

.Ident-Loja {
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
.Ident-Loja img {
  width: 100%;
  max-width: 100px;
  margin-right: 15px;
}
.Ident-Loja h1 {
  color: var(--green-two);
}

.Dados-Info {
  margin-bottom: 30px;
}
.Dados-Info h4 {
  font-size: 26px;
  font-weight: 700;
  color: var(--green-oneother);
}
.Dados-Info .Input-Simples {
  width: 100%;
  position: relative;
  display: flex;
}
.Dados-Info .Input-Simples input {
  width: 100%;
}

.Pesquisa {
  margin: 0 0 0 10px;
  position: relative;
  height: 100%;
  min-height: 65px;
  max-height: 65px;
  width: 100%;
}
.Pesquisa input {
  padding: 12px 28px;
  border: none;
  font-size: 1rem;
  border-radius: 10px;
  height: 100%;
  min-height: 65px;
  width: 100%;
}
.Pesquisa button {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: var(--gray-dark);
}
.Pesquisa button:hover {
  background-color: var(--gray-dark);
  color: #fff;
  width: 100px;
  transition: all .25s;
}

.Filters {
  display: flex;
  padding: 12px 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
}
.Filters:hover .icon.icon-toggle div:first-child {
  width: 30px;
}
.Filters:hover .icon.icon-toggle div:last-child {
  width: 20px;
}
.Filters .icon div {
  background: var(--gray-medium)
}
.Filters .wrapper-items {
  width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  visibility: hidden;
  transition: overflow .0s, width .03s;
}
.Filters .wrapper-items.active {
  margin: 0 10px 0 32px;
  visibility: visible;
  width: 100%;
  -webkit-transition: .7s ease-in-out;
  -moz-transition: .7s ease-in-out;
  -o-transition: 7s ease-in-out;
  transition: .7s ease-in-out;
}
.Filters .items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}
.Filters .text {
  font-size: 16px;
  margin: 3px 0 0 5px;
}
.Filters .option {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Ordenar {
  min-width: 230px;
  padding-left: 20px;
}

.trCabecalho {
  background: var(--green-oneother);
  height: 50px;
  text-align: left;
}
.trCabecalho th {
  color: var(--white);
  padding: 0 20px;
  font-weight: 600;
}

table.simples {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
table.simples a {
  display: contents;
}
table.simples tr {
  font-size: 16px;
  color: var(--gray-dark);
  line-height: 1.5;
  font-weight: unset !important;
}
table.simples tr a {
  color: var(--gray-dark);
}
table.simples tbody:nth-child(even) tr:nth-child(odd) {
  background-color:var(--white);
}
table.simples tbody:nth-child(even) tr:nth-child(even) {
  background-color: #f2f2f2;
}
table.simples tbody:nth-child(even) tr:hover {
  background-color: var(--green-one);
  color: var(--white);
}
table.simples tbody:nth-child(even) tr:hover a {
  color: var(--white);
}
table.simples td {
  padding: 13px 15px;
}
table.simples td img {
  height: auto;
  width: 30px;
}

.cancelado {
  height: 100%;
  background: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 20px;
  padding: 0 20px;
  right: 0;
  position: absolute;
  cursor: not-allowed;
}

main {
  overflow-y: scroll;
}
main > div {
  margin-right: 20px;
}

.Paginacao {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.Paginacao label {
  border-radius: 8px;
  font-weight: 800;
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
}
.Paginacao * {
  -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.paginacao-item {
  color: var(--gray-dark);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  font-size: 14px;
  font-weight: 700;
  background: #ddd;
  border-radius: 10px;
  margin: 0 3px;
}
.paginacao-item:hover, .paginacao-item-active {
  background-color: var(--gray-dark);
  color: var(--gray-light);
  border: 1px solid var(--gray-dark);
}

.Input-Simples {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
}
.Input-Simples label {
  margin: 0 10px 0;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
}
.Input-Simples input, textarea {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  border: 2px solid transparent;
  font-size: 18px;
}

.input-error {
  border: 1px solid red!important;
}
textarea {
  width: 100%;
}

.border-green {
  border-left: 5px solid var(--green-one);
  padding-left: 10px;
  font-size: 18px;
}

.Input-Valor input, .Input-Valor .input {
  border: 2px solid var(--green-oneother);
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 10px;
}
.Input-Valor .input {
  background-color: var(--green-one);
  color: white;
  font-weight: 700;
}
.Input-Valor .input-nostyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7ebee;
}
.Login .login-content {
  width: 100%;
  max-width: 460px;
  padding: 60px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  border-radius: 16px;
  box-shadow: 0 30px 10px -20px rgba(0,0,0,.05);
}
.Login .login-content .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;
}
.Login .login-content .logo img {
  height: 100px;
}
.Login .Input-Simples {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
}
.Login .Input-Simples label {
  margin: 0;
}
.Login .Input-Simples input {
  border: none!important;
  border-radius: 0!important;
  background: white;
  border-color: var(--gray-four);
  color: var(--gray-dark);
  border-bottom: 1.8px solid rgba(0,0,0,.3)!important;
  transition: all .4s;
  font-size: 18px!important;
  padding: 10px 5px;
  font-weight: 600!important;
}
.Login .Input-Simples input:focus {
  color: var(--green-three);
  border-bottom: 1.8px solid var(--green-three);
  transition: all .4s;
}
.Login h1 {
  font-size: 3rem;
  color: var(--green-two);
  margin-bottom: 0;
}
.Login a {
  text-decoration: none;
  color: #474747;
}
.Login .Card .Input-Simples {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Login-Gears {
  margin: 20px 0;
  width: 100%;
}
.Login .actions-login {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0;
  align-items: center;
}

.imagem-remover {
  background-color: var(--red);
  width: 20px;
  height: 20px;
  font-size: 1rem;
  color: var(--white);
  border-radius: 100px;
  cursor: pointer;
}
.imagem-remover:hover {
  background-color: #a10b0b;
}

.variacao-item {
  background-color: var(--gray-light);
  color: var(--gray-dark);
  padding: 10px 15px;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
}
.variacao-item-ativa, .variacao-item:hover {
  background-color: var(--gray-dark);
  color: var(--gray-light);
}

/* 
 * UTILS XLEG OFICIAL
 */

/* ALERTS */
.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 20px;
  right: 0;
  z-index: 9999;
  color: #fff;
  width: 300px;
  border-radius: 15px 0 15px 15px;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0 10px 20px rgba(0,0,0,.1);
  transition: all .2s;
  right: 20px;
}
.alert.active {
  right: 20px;
}

.alert.success,
.alert-success {
  background: var(--green-one);
  color: #fff;
}
.alert.error,
.alert-danger {
  background: var(--lilac);
  color: #fff;
}

/* BUTTONS */
.btn {
	border-radius: .1875rem;
	font-size: 1rem;
	line-height: 1.5;
	padding: 1rem 1.50rem;
	transition: color .5s cubic-bezier(.2,1,.2,1),background-color .5s cubic-bezier(.2,1,.2,1),border-color .5s cubic-bezier(.2,1,.2,1),box-shadow .5s cubic-bezier(.2,1,.2,1);
	border: 1px solid transparent;
	box-shadow: 0 0 0 0 transparent;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	font-weight: 800;
	position: relative;
}
.btn:focus,
.btn:hover {
 text-decoration:none!important;
}
.btn.disabled,
.btn:disabled {
 box-shadow:none;
 cursor:not-allowed;
 opacity:.5;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.btn:not(:disabled):not(.disabled) {
 cursor:pointer
}
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
 background-image:none
}

.font-large {
	font-size: 20px!important;
}

.btn-small {
	padding: 6px 10px!important;
}

.btn-all {
	width: 100%!important;
}

.btn-primary {
	background-color: var(--green-three);
	border-color: var(--green-three);
	box-shadow: 0 0 0 0 transparent;
	color: #fafbfc;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:active {
 	box-shadow: 0 0 0 .1875rem rgba(18, 179, 78, 0.79);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 0 0 .1875rem rgba(18, 179, 78, 0.79);
}

.btn-cancelled {
	background-color: var(--lilac);
	border-color: var(--lilacother);
	box-shadow: 0 0 0 0 transparent;
  color: #fafbfc;
  opacity: 0.6;
}
.btn-cancelled.focus,
.btn-cancelled:focus,
.btn-cancelled:active {
 	box-shadow: 0 0 0 .1875rem rgba(247, 92, 3, 0.5);
}
.btn-cancelled:not(:disabled):not(.disabled).active:focus,
.btn-cancelled:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 0 0 .1875rem rgba(247, 92, 3, 0.5);
}

.btn-success {
  background-color: var(--green-oneother);
  border-color: var(--green-oneother);
  box-shadow: 0 0 0 0 transparent;
  color: #fafbfc;
}
.btn-success.focus,
.btn-success:focus,
.btn-success:active {
 	box-shadow: 0 0 0 .1875rem rgba(158, 171, 124, 0.4);
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 0 0 .1875rem rgba(158, 171, 124, 0.4);
}

.btn-danger {
	background-color: var(--red);
	border-color: var(--red);
	box-shadow: 0 0 0 0 transparent;
	color: #fafbfc;
}
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:active {
 	box-shadow: 0 0 0 .1875rem rgba(204, 73, 73, .5);
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 0 0 .1875rem rgba(204, 73, 73, 0.5);
}

.btn-whatsapp {
	background-color: #25d366;
	border-color: #25d366;
	box-shadow: 0 0 0 0 transparent;
	color: #fafbfc;
}
.btn-whatsapp.focus,
.btn-whatsapp:focus,
.btn-whatsapp:active {
 	box-shadow: 0 0 0 .1875rem rgba(18, 179, 78, 0.79);
}
.btn-whatsapp:not(:disabled):not(.disabled).active:focus,
.btn-whatsapp:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 0 0 .1875rem rgba(18, 179, 78, 0.79);
}

.btn-default {
	background-color: #fff;
	box-shadow: 0 10px 0 0 transparent;
	color: var(--green-two);
}
.btn-default.focus,
.btn-default:focus,
.btn-default:active {
 	box-shadow: 0 10px 0 .1875rem rgb(227, 227, 227);
}
.btn-default:not(:disabled):not(.disabled).active:focus,
.btn-default:not(:disabled):not(.disabled):active:focus {
 	box-shadow: 0 10px 0 .1875rem rgb(227, 227, 227);
}

/* CSS XLEG OFICIAL */
.ContentDetails {
  padding: 40px;
  background: var(--white);
  position: relative;
  width: 100%;
  max-width: 100%;
}
.ContentDetails .Section {
  position: relative;
}
.ContentDetails .Section .Headline {
  margin: 40px 0;
}
.ContentDetails .Section .Headline h1 {
  margin: 0 0 10px;
  font-size: 40px;
}
.ContentDetails .Section .Headline .action {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
}
.ContentDetails .Section .Headline .action .alert {
  margin: 0 10px;
}

.Detalhes-Pedido {
  display: flex;
  align-items: center;
  margin: 40px 0;
}
.Detalhes-Pedido .Detalhes-Titulo {
  width: 100%;
}
.Detalhes-Pedido .Titulo-primario {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  color: var(--lilac);
}
.Detalhes-Pedido .Titulo-secundario {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
}

.List-Status .status {
  background: var(--gray-one);
  margin: 0 0 10px;
  padding: 10px;
}
.List-Status .status span {
  padding-left: 5px;
  font-size: 18px;
  color: var(--gray-dark);
  font-weight: 600;
}

.Configs .List-Status .status {
  border: none;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr 48px;
  grid-gap: 10px;
  height: 48px;
  margin: 0 0 5px;
}
.Configs .List-Status .status span {
  font-size: 20px;
  font-weight: 600;
  
  padding: 10px 20px;
  background: var(--purple-one);
  color: white;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.Configs .List-Status .status button {
  width: 48px;
  min-width: 48px;
  height: 48px;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: var(--purple-one);
  color: white;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  transition: 1s;
}
.Configs .List-Status .status button:hover {
  background: var(--purple-onehover);
}
.Configs .List-Status .list-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
}
.List-Status .list-add input {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  border: 2px solid transparent;
  min-height: 48px;
  font-size: 18px;
  margin-right: 10px;
}
.List-Status .list-add input:hover {
  border: 2px solid var(--green-one);
}
.List-Status .list-add button {
  width: 48px;
  min-width: 48px;
  height: 48px;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: var(--green-two);
  color: white;
  
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .8;
}
.List-Status .list-add button:hover {
  opacity: 1;
}

.NotFoundData {
  padding: 10px 0;
  color: var(--gray-dark)!important;
  font-size: 20px!important;
  font-weight: 400;
  margin: 0!important;
}

.upsell-products {
  margin: 40px 0 0;
}
.upsell-products h4 {
  font-size: 40px;
  margin: 0;
  color: var(--gray-five);
}
.product-image {
  border-radius: 20px;
  box-shadow: 0 0 0 10px var(--gray-one);
  overflow: hidden;
  max-width: 400px;
  max-height: 400px;
  height: 400px;
  width: 400px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0;
  border: 10px solid #fff;
}
.product-image img {
  display: block;
}
.product-image .icon-photo {
  font-size: 60px;
  background: var(--gray-one);
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: var(--gray-medium);
  cursor: pointer!important;
}

.single-image input {
  display: none;
}

.Product {
  width: 100%;
}

.Texto-Dados {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 10px;
}

select {
  display: flex;
  margin: 0 0 10px;
  padding: 8px 5px;
  font-size: 16px;
}

.TableBoxes {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
}
.TableBoxes .item {
  display: flex;
  align-items: flex-end;
  height: 360px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  border: 6px solid var(--gray-light);
  position: relative;
}
.TableBoxes .item.active {
  border: 6px solid var(--purple-one);
}
.TableBoxes .item.disable {
  opacity: 0.7;
}
.TableBoxes .item .details {
  width: 100%;
  background: var(--gray-light);
  padding: 20px 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
}
.TableBoxes .item .details .text {
  display: flex;
  flex-direction: column;
}
.TableBoxes .item .details .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.1;
}
.TableBoxes .item .details .price {
  font-weight: 700;
  font-size: 22px;
  color: #555;
  line-height: 1.1;
  position: absolute;
  top: -2px;
  right: -2px;
  padding: 12px 20px;
  background: var(--gray-light);
  border-radius: 0 20px;
}
.TableBoxes .item.active .details,
.TableBoxes .item.active .price {
  background: var(--purple-one);
  color: #fff;
}
.TableBoxes .item .details .action {
  min-width: 40px;
  min-height: 40px;
  background: #fff;
  border-radius: 100%;
  max-height: 40px;
  max-width: 40px;
  border: 4px solid var(--gray-medium);
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TableBoxes .item.active .details .action {
  border-color: var(--purple-onehover);
  color: #fff;
  font-size: 20px;
  background: var(--purple-onehover);
}

.novoUpsell .fields {
  margin: 40px 0 0;
}

.entregaDetails {
  margin: 0 0 60px;
}

.cardDelivery {
  font-size: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  
  padding: 40px;
}
.cardDelivery_wrapper,
.cardDelivery {
  /* max-height: 600px;
  min-height: 600px;
  max-width: 1200px;
  min-width: 1200px; */
  max-height: 500px;
  min-height: 500px;
  min-width: 1000px;
  max-width: 1000px;
  background: #fff;
  min-width: 1000px;
}
.cardDelivery .cardDelivery_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardDelivery .cardDelivery_header .spanFromTo {
  display: flex;
  flex-direction: column;
}
.cardDelivery .cardDelivery_header .spanFromTo span {
  font-size: 16px;
  color: #3f2717;
  font-weight: 500;
  margin: 0;
  line-height: 1;
}
.cardDelivery .cardDelivery_header h6 {
  margin: 0;
  font-size: 20px;
  color: var(--green-oneother);
}
.cardDelivery .cardMessage {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px 120px;
  line-height: 1.4;
  max-height: 240px;
  min-height: 240px;
  color: #3f2717;
  font-size: 30px;
  font-family: monospace;
  font-weight: 500!important;
  letter-spacing: -1px;
}
.cardDelivery .cardMessage.small {
  font-size: 23px;
  margin: 30px 0;
  line-height: 1.4;
  margin: 30px 0;
  padding: 0 60px !important;
}
.cardDelivery .cardMessage.smaller {
  font-size: 19px;
  margin: 30px 0;
  line-height: 1.4;
  margin: 30px 0;
  padding: 0 30px !important;
}

.cardDelivery .logo {
  width: 100%;
  max-width: 200px;
}
.cardDelivery_wrapper {
  position: relative;
}
.cardDelivery_wrapper .printButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  font-size: 46px;
  transition: font-size .4s;
  color: var(--green-oneother);
  border: none;
  font-weight: 800;
  cursor: pointer;
  opacity: 0;
  transition: opacity .4s;
  border: 4px dotted var(--gray-light) !important;
  margin: 0;
  padding: 0;
}
.cardDelivery_wrapper .printButton:hover {
  font-size: 40px;
  transition: font-size .9s;
  opacity: 1;
  transition: opacity .4s;
}
.cardDelivery_wrapper .printButton:hover {
  border: none;
}

.disabled {
  opacity: 0.5!important;
  cursor: wait!important;
}

.ViewDetails {
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
  width: 100%;
}
.ViewDetails .details-tools,
.ViewDetails .details-tools .tools {
  display: flex;
  justify-content: space-between;
}
.ViewDetails .details-tools .tools .orderCancelled {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  background: var(--lilac);
  color: #fff;
  border-radius: 10px;
  font-weight: 800;
}
.ViewDetails .order {
  background: #fff;
  margin: 30px 0;
  border-radius: 20px;
  padding: 40px;
}
.ViewDetails .order .order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 40px;
  margin: 0 0 30px;
}
.ViewDetails .order .order-header .item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ViewDetails .order .order-header .item span {
  margin: 0 0 5px;
}
.ViewDetails .order .order-header .item span.bold {
  font-weight: 800;
}
.ViewDetails .order .order-header .item span.manual {
  font-size: 16px;
  background: #32ade8;
  padding: 4px 10px;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 0 10px;
}
.ViewDetails .order .order-header .order-status {
  width: 100%;
}
.ViewDetails .order .order-header .order-status {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  margin: 0;
}
.ViewDetails .order .order-header .order-status .item-status {
  display: flex;
  flex-direction: column;
  color: var(--gray-dark);
  background: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px dashed rgba(0,0,0,.5);
}
.ViewDetails .order .order-header .order-status .status-text {
  font-size: 22px;
  color: var(--green-one);
  font-weight: 800;
  line-height: 1.1;
}
.ViewDetails .order .item h1 {
  margin: 0;
  font-size: 36px;
  color: var(--gray-dark);
  display: flex;
  align-items: center;
}
.ViewDetails .Data {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ViewDetails .Data > div {
  width: 50%;
}
.ViewDetails .item-data {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  margin: 0 0 20px;
}
.ViewDetails .Data h2 {
  font-size: 24px;
  color: var(--gray-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 0 10px;
}
.ViewDetails .textData {
  display: flex;
  flex-direction: column;
}
.ViewDetails .textData .title {
  font-size: 16px;
  color: var(--green-oneother);
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0!important;
}
.ViewDetails .textData .text {
  font-size: 18px;
  color: var(--gray-dark);
  font-weight: 700;
}

.textData .wrapper-edit {
  margin: 0 0 20px;
}
.textData .title,
.item > label,
.telephones > label,
.inputSearchProducts > .title {
  margin: 0 10px 0;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
}
.textData .edit,
.textData .value input,
.textData select,
.textData textarea {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  border: 2px solid transparent;
}
.textData .edit .wrapper-value {
  width: 100%;
}
.textData .edit .error-message {
  color: var(--lilac);
  width: 100%;
  text-align: right;
  margin: 0 10px 0 0;
}
.textData .edit:hover {
  border-color: var(--green-one);
  transition: all .2s;
}
.textData .edit:hover > svg {
  color: var(--green-oneother);
  transition: all .2s;
}
.textData .edit .value {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.textData .value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textData .edit {
  min-height: 48px;
  font-size: 18px;
}
.textData .edit svg {
  min-width: 18px;
  min-height: 18px;
}
.textData .value input,
.textData .value button {
  max-height: 48px;
  min-height: 48px;
  font-size: 18px;
}
.textData .value button {
  min-width: 48px;
  max-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray-dark);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 5px;
  color: #fff;
  transition: .2s;
}
.textData .value button:hover {
  transition: .2s;
}
.textData .value button.success {
  background-color: var(--purple-one);
}
.textData .value button.success:hover {
  background-color: var(--purple-onehover);
}
.textData .value button.danger {
  background-color: var(--lilac);
}
.textData .value button.danger:hover {
  background-color: var(--lilacother);
}

.imagesGrid {
  padding: 40px;
  background: white;
  border-radius: 8px;
}
.imagesGrid h1 {
  margin: 0 0 10px;
}
.imagesGrid .head-action {
  margin: 20px 0 0;
}
.imagesGrid .head-action input {
  width: 100%;
  padding: 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 8px;
}
.imagesGrid .images-container {
  margin: 40px 0 0;
  border-radius: 5px;
  background-size: cover;
  flex: 1 1;
  flex-basis: 100px;
  display: flex;
}
.imagesGrid .images-container .image {
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 5px;
}
.imagesGrid .images-container .image .remove {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: var(--lilac);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999;
  color: #fff;
  font-size: 20px;
}
.loading svg {
  height: 40px;
  animation: AnimationLoading 1.5s linear infinite;
}
@keyframes AnimationLoading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  .cardMessage {
    -webkit-print-color-adjust: exact;
  }
}
.OrderPrint {
  position: absolute;
  width: 100%;
  padding: 80px 20px;
  margin: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 999999;
  background: rgb(208 208 208 / 88%);
  grid-gap: 10px;
  height: 100%;
  border-radius: 8px;
}
.OrderPrint .actions {
  display: flex;
  flex-direction: column;
}
.OrderPrint .actions button {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}
.OrderPrint_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 793px;
  height: 1122px;
  background: #fff;
  padding: 60px;
}
.OrderPrint_wrapper .column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  grid-gap: 10px;
}
.OrderPrint_wrapper .column ._header {
  display: flex;
  justify-content: space-between;
}
.OrderPrint_wrapper .column ._header span {
  font-size: 18px;
}
.OrderPrint_wrapper .column ._header ._cardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.OrderPrint_wrapper .column ._header ._cardInfo ._card {
  font-size: 16px!important;
  font-weight: 600;
  
  background-color: var(--green-one);
  color: #fff;
  border-radius: 8px;
  padding: 6px 10px;
  margin: 6px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}
.OrderPrint_wrapper .column ._header ._cardInfo ._card ._bold {
  font-size: 16px!important;
  font-weight: 800;
}
.OrderPrint_wrapper .column ._header ._cardInfo ._card.not {
  background: var(--lilac);
}
.OrderPrint_wrapper .column {
  justify-content: center;
}
.OrderPrint_wrapper .column:first-child {
  border-bottom: 2px dashed var(--green-one);
  justify-content: flex-start;
  margin: 0 0 10px;
}
.OrderPrint_wrapper .column img {
  width: 250px;
  margin: 0 0 20px;
}
.OrderPrint_wrapper .column ._items {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 40px;
}
.OrderPrint_wrapper .column ._item {
  display: flex;
  flex-direction: column;
}
.OrderPrint_wrapper .column .title {
  font-weight: 500;
  font-size: 18px;
  color: var(--green-oneother);
}
.OrderPrint_wrapper .column .text {
  font-size: 18px;
}
@media print {
  .OrderPrint_wrapper {
      width: 100%;
  }
}
.OrderPrint_wrapper .logo {
  width: 200px;
}

.Report {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 0 20px;
}
.Report ._head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.Report ._head h1 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 700;
  margin: 0 0 10px;
}
.Report ._head button {
  padding: 16px 20px;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  background: var(--green-one);
  color: #f7f7f7;
  font-weight: 800;
  border-radius: 8px;
  cursor: pointer;
}
.Report .filters {
  display: flex;
  grid-gap: 40px;
  margin: 20px 0;
}
.Report .filters .item {
  display: flex;
  flex-direction: column;
}

.icon.icon-toggle {
  height: 100%;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.icon.icon-toggle div {
  background: var(--gray-dark);
  width: 30px;
  height: 6px;
  margin: 0 0 3px;
  border-radius: 50px;
  transition: all .4s;
}
.icon.icon-toggle.active div {
  background: var(--green-oneother);
  transition: all .4s;
}
.icon.icon-toggle.active div:first-child {
  width: 30px;
}
.icon.icon-toggle div:first-child {
  width: 20px;
}
.icon.icon-toggle div:last-child {
  margin: 0;
}

.btnIcon {
  width: 75px;
  height: 65px;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 32px;
  color: var(--gray-dark);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative
}
.btnIcon:hover {
  border: 2px dashed white;
  color: #fff;
  transition: all .2s;
}
.btnIcon.plus:before {
  content: "";
  width: 28px;
  height: 6px;
  border-radius: 50px;
  background: var(--gray-dark);
}
.btnIcon.plus:after {
  content: "";
  width: 6px;
  height: 28px;
  border-radius: 50px;
  background: var(--gray-dark);
  position: absolute;
}

.form .double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.form .streetAndNumber {
  display: grid;
  grid-template-columns: 4fr 140px;
  grid-gap: 10px;
}
.form .TextArea textarea {
  width: 100%;
  padding: 14px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
  background: #fff;
  margin: 0 0 10px;
  resize: none;
}
.form .Input input {
  width: 100%;
  padding: 14px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
  background: #fff;
  margin: 0 0 10px;
}
.form .Input span {
  margin: -6px 0 10px 12px;
  display: flex;
}
.form .Input span.danger {
  color: var(--lilac)
}
.form .Input select {
  width: 100%;
  height: 100%;
  max-height: 56px;
  margin: 0 0 10px;
  border: none;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 17px;
}
.ButtonSubmit {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  align-items: center;
}
.ButtonSubmit .errors {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px 0 0;
  font-size: 20px;
}
.ButtonSubmit button {
  padding: 18px 24px;
  font-size: 16px;
  background: var(--green-three);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 220px;
  cursor: pointer;
}
.ButtonSubmit button:hover {
  background: var(--green-two);
}
.form .inputValue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .inputValue {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #333;
  border-radius: 12px;
  overflow: hidden;
}
.form .inputValue input {
  text-align: center;
  padding: 0;
  height: 100%;
  min-height: 60px;
  margin: 0;
  border-radius: 0;
  max-width: 120px;
}
.form .inputValue span {
  width: 60px;
  height: 60px;
  background: #dfdfdf;
  font-size: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .sectionClient h4 {
  font-size: 19px;
  margin: -14px 0 0 5px;
  color: var(--green-oneother);
}

.newProduct .success .action {
  margin: 20px 0;
  display: flex;
}
.newProduct .success .action a {
  padding: 14px 20px;
  background: var(--green-oneother);
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  border-radius: 8px;
}
.newProduct .success .action a:hover {
  background: var(--green-one);
  transition: all .2s;
}

.Categories .Page-Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.inputNeighborhoodWithValue {
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  grid-gap: 5px;
}
.inputNeighborhoodWithValue button {
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  background-color: var(--green-two);
  transition: .4s ease;
}
.inputNeighborhoodWithValue button:hover {
  background-color: var(--green-three);
}

.Configs {
  width: 100%;
}
.Configs .wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  margin: 60px 0 0;
}
.Configs .wrapper .item-section {
  margin: 0 0 60px;
  padding: 0 0 60px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.Configs .wrapper .item-section:last-child {
  border: none;
}
.Configs .wrapper .item-section .item-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.Configs .wrapper .item-section .item-triple {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.Configs .head {
  display: flex;
  flex-direction: column;
}
.Configs .head h2 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 500;
  margin: 0 0 40px;
  display: flex;
  align-items: center;
}
.Configs .head h2 i {
  margin: 0 20px 0 0;
  font-size: 30px;
}
.Configs .menu {
  display: flex;
  display: flex;
  grid-gap: 5px;
}
.Configs .menu button {
  margin: 0 0 10px;
  display: flex;
  padding: 15px 30px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 14px;
  color: #ffffff;
  background: #686f79;
  transition: 1s ease;
  cursor: pointer;
  border: 3px solid rgba(0,0,0,.02);
}
.Configs .menu button.active,
.Configs .menu button:hover {
  background: #35393e;
}

.Configs .wrapper h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
.Configs .wrapper h3 .success {
  font-weight: 800;
  color: var(--green-two)
}
.Configs .wrapper h3 .danger {
  font-weight: 800;
  color: var(--lilac)
}
.Configs .inputSearchProducts {
  margin: 0 0 40px;
}
.Configs .config-neighborhood .categories-items .item > span {
  width: calc(100% - 34px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Configs .config-neighborhood .categories-items .item .value {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin: 3px 0 0;
  color: #6f6f6f;
}

.Configs .config-dates .inputs {
  display: grid;
  grid-template-columns: 1fr 2fr .5fr;
  grid-gap: 5px;
}
.Configs .config-dates .inputs button {
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  background-color: var(--green-two);
  transition: .4s ease;
}
.Configs .config-dates .inputs button:hover {
  background-color: var(--green-three);
}
.Configs .config-dates .inputs .checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 12px;
}
.Configs .config-dates .inputs .checkboxes .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Configs .config-dates .inputs .checkboxes .checkbox input {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  padding: 0;
  border-color: #c1c1c1;
}
.Configs .config-dates .inputs .checkboxes .checkbox input::after {
  width: 8px;
  height: 16px;
  top: 4px;
  left: 10px;
}
.Configs .config-dates .inputs .checkboxes .checkbox input:checked {
  border: none;
  background-color: var(--green-two);
}
.Configs .config-dates .categories-items {
  display: flex;
  flex-direction: column;
}
.Configs .config-dates .categories-items .item {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.Configs .config-dates .categories-items .item .period {
  display: flex;
  flex-direction: column;
}
.Configs .config-dates .categories-items .item .period label {
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}
.Configs .config-dates .categories-items .item .period span {
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
}


.notification-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  
  display: flex;
  justify-content: center;
}
.notification-wrapper.active {
  z-index: 9999;
  opacity: 1;
}
.notification {
  background: rgb(98, 182, 112);
  padding: 18px 60px;
  border-radius: 12px;
  font-size: 18px;
  color: white;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: -100px;

  transition: 1s ease-in-out;
  opacity: .96;
}
.notification.active {
  top: 10px;
}
.error .notification {
  background: rgb(182, 98, 98)!important;
}

.EmailMarketing .modalOfficial {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px;
  height: 100vh;
  background: var(--background-modal);
  backdrop-filter: blur(6px);

  display: none;
  justify-content: center;
  align-items: center;
}
.EmailMarketing .modalOfficial.active {
  display: flex;
}
.EmailMarketing .modalOfficial .content {
  background: white;
  border-radius: 16px;
  padding: 60px;
  width: 100%;
  max-width: 900px;

  position: relative;
  transition: .4s ease-in-out;
}
.EmailMarketing .modalOfficial .content.min {
  max-width: 660px;
}
.EmailMarketing .modalOfficial .content .close {
  width: 50px;
  height: 50px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 30px;

  position: absolute;
  right: 60px;
  cursor: pointer!important;
  z-index: 99;
}
.EmailMarketing .modalOfficial .content .close:hover {
  background: rgba(0,0,0,.1);
}

.EmailMarketing .modalOfficial .deleting h2 {
  font-size: 30px;
  font-weight: 300;
}
.EmailMarketing .modalOfficial .deleting h2 span {
  font-weight: 800;
}
.EmailMarketing .modalOfficial .deleting .actions {
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 0;
}
.EmailMarketing .modalOfficial .deleting .actions button {
  height: 100%;
  background: rgb(52, 202, 157);
  color: white;
  padding: 15px 28px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  display: flex;
  align-items: center;
  opacity: .9;
}
.EmailMarketing .modalOfficial .deleting .actions button:hover {
  opacity: 1;
}
.EmailMarketing .modalOfficial .deleting .actions button.cancel {
  background: rgb(202 52 52);
}

.ClientView .details .head,
.Category .details .head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ClientView .details .head .btntool,
.Category .head .btntool {
  margin: 0 40px 0 0;
}
.ClientView .details .body,
.Category .details .body {
  margin: 40px 0;
}
.ClientView .details .body .item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 0 10px;
}
.ClientView .orders > h2,
.Category .products > h2,
.upsell-products h2 {
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 1;
  color: var(--gray-dark);
  font-weight: 500;
  margin: 100px 0 40px;
  text-align: center;
}

.SectionOrders,
.SectionDelivery,
.Clients,
.Products {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.toggle {
  width: 54px;
  height: 30px;
  background: #e3e3e3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 4px;
  position: relative;
  margin: 0 0 0 auto;
  margin-right: 20px;
}
.toggle.true {
  background: #9ac125;
}
.toggle-selector {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: white;

  position: absolute;
  top: 3px;
  left: 3px;
  
  transition: .2s linear;
  cursor: pointer;
}
.toggle-selector.true {
  left: initial;
  right: 3px;
  border-color: #9ac125;
}

.absoluteEdit {
  position: absolute;
  top: 4px;
  right: 4px;
  
  font-size: 20px;
}
.absoluteEdit svg {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  fill: #938e8e;
  padding: 10px;
}
.absoluteEdit svg:hover {
  background: rgba(0,0,0,.2);
  fill: white;
}