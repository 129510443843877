.discount h3 {
  display: flex;
  align-items: center;
}
.discount h3 span {
  margin: 0 10px;
}

.discount .new {
  padding: 6px 10px 4px;
  border-radius: 5px;
  background: #89c600;
  color: white;

  font-size: 12px;
  font-weight: 800;
  line-height: 1;
}

.discount .list .item {
  width: 100%;

  display: grid;
  grid-template-columns: 1.5fr 2fr 40px;

  padding: 13px 10px 10px 20px;
  margin: 0 0 10px;
  
  background: linear-gradient( 45deg, #e9e9e9, transparent);
  
  border-radius: 10px;
  border: 2px solid #fff;
  
  transition: .3s ease-in-out;
  cursor: pointer;

  color: #61686e;
  font-size: 18px;
  font-weight: 600;
}
.discount .list .item:hover {
  color: #d26868;
  border-color: #ff9494;
}
.discount .list .item:hover .remove::before,
.discount .list .item:hover .remove::after,
.discount .input.product:hover .remove::before,
.discount .input.product:hover .remove::after {
  background: #ff9494;
}
.discount .item .info {
  display: flex;
  flex-direction: column;
}
.discount .item .info label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin: 0 0 4px;
}
.discount .item .info span {
  font-size: 17px;
  font-weight: 800;
  line-height: 1;
}
.discount .remove {
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.discount .remove::before,
.discount .remove::after {
  content: "";
  width: 25px;
  height: 5px;
  background: #fff;
  border-radius: 50px;
  transition: all .8s;
  position: absolute;
}
.discount .remove::before { transform: rotate(45deg); }
.discount .remove::after { transform: rotate(-45deg); }

.new-discount {
  display: flex;
  flex-direction: column;
}
.new-discount .grid {
  display: flex;
  margin: 0 0 6px;
}
.new-discount .input {
  width: 100%;

  display: flex;
  position: relative;
  margin-right: 6px;
}
.new-discount .input input {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 20px;
  border: none;
  border-radius: 10px;
}
.new-discount .input.code span {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 20px;
}
.new-discount .input.code input {
  padding-left: 40px;
}

.new-discount .input.product {
  display: flex;
  position: relative;
}
.new-discount .input.product .results {
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0 4px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
}
.new-discount .input.product .result {
  padding: 6px;
  background: white;
  width: 100%;
  border-radius: 10px;

  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  cursor: pointer;
  
  opacity: .7;
  transition: .2s ease-in-out;

  display: flex;
  align-items: center;
}
.new-discount .input.product .result:hover {
  opacity: 1;
}
.new-discount .input.product .result .image {
  min-width: 40px;
  height: 40px;
  background-color: rgba(180, 180, 180);
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
}
.new-discount .input.product .result .remove {
  margin-right: 5px;
}
.new-discount .input.product .result .remove::before,
.new-discount .input.product .result .remove::after {
  background: #ff9494;
}
.new-discount .input.product .result span {
  width: 100%;
}

.new-discount .checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 12px;
  padding: 10px 16px;
  width: calc(100% + 200px);
}
.new-discount .checkboxes .checkbox {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.new-discount .checkboxes .checkbox input {
  min-width: 30px;
  height: 30px;
  margin-right: 10px;
  padding: 0;
  border-color: #c1c1c1;
}
.new-discount .checkboxes .checkbox input::after {
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
}
.new-discount .checkboxes .checkbox input:checked {
  border: transparent;
  background-color: var(--green-two);
}
.new-discount .checkboxes .checkbox label {
  font-size: 16px;
  font-weight: 800;
  color: rgba(0,0,0,.6);
  width: 100%;
}

.discount button.addnew {
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  background-color: var(--green-two);
  transition: .4s ease;
}