:root {
  --background-modal: rgb(116 116 116 / 82%);
}

.EmailMarketing {
  width: 100%;
}

.EmailMarketing h2 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 500;
  display: flex;
  align-items: center;
}
.EmailMarketing h2 i {
  margin: 0 20px 0 0;
  font-size: 30px;
}

.EmailMarketing .EmailEditor {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px;
  height: 100vh;
  background: var(--background-modal);
  backdrop-filter: blur(6px);
}
.EmailMarketing .EmailEditor .content {
  padding: 60px 80px;
  height: 100vh;
  z-index: 2;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EmailMarketing .EmailEditor.active {
  display: flex;
  flex-direction: column;
}
.EmailMarketing .EmailEditor .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(8% - 20px);
  margin: 0 0 20px;
  width: 100%;
}
.EmailMarketing .EmailEditor .head .title {
  color: white;
  width: 100%;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
}
.EmailMarketing .EmailEditor .head .title .input-edit {
  display: flex;
}
.EmailMarketing .EmailEditor .head .title span {
  opacity: 0;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  top: -200px;
  right: -200px;
  padding: 4px 10px;
}
.EmailMarketing .EmailEditor .head .title span i {
  margin: 0 0 0 10px;
}
.EmailMarketing .EmailEditor .head .title .input-edit input {
  opacity: 0;
  font-size: 30px;
  font-weight: 700;
  background: transparent;
  border: none;
  color: white;
  position: absolute;
  top: -200px;
  right: -200px;
  padding: 4px 10px;
}
.EmailMarketing .EmailEditor .head .title .input-edit input:focus {
  border: 3px solid black;
  border-radius: 12px;
}
.EmailMarketing .EmailEditor .head .title .visible,
.EmailMarketing .EmailEditor .head .title .input-edit .visible {
  opacity: 1;
  position: relative;
  top: 0;
  right: 0;
}
.EmailMarketing .EmailEditor .head .title button {
  padding: 10px 20px;
  border-radius: 12px;
  border: none;
  background: black;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 0 0 10px;
}
.EmailMarketing .EmailEditor .head .title i {
  margin: 0 10px 0 0;
  font-size: 24px;
}
.EmailMarketing .EmailEditor .actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.EmailMarketing .EmailEditor .actions button {
  background: rgb(52, 202, 157);
  color: white;
  padding: 10px 28px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;

  display: flex;
  align-items: center;
}
.EmailMarketing .EmailEditor .actions button:hover {
  opacity: .9;
}
.EmailMarketing .EmailEditor .actions button.save {
  background: rgb(115, 177, 235);
}
.EmailMarketing .EmailEditor .actions button.exit {
  margin-left: 20px;
  background: rgb(235, 129, 115);
}
.EmailMarketing .EmailEditor .actions button span {
  margin: 0 0 0 10px;
  font-size: 18px;
}
.EmailMarketing .EmailEditor .content > div:last-child {
  min-height: 92%!important;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

.EmailMarketing .menu {
  height: 67px;
  margin: 40px 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.EmailMarketing .menu button {
  height: 100%;
  width: 100%;
  border: none;
  background: #686f79;
  color: white;
  cursor: pointer;
  border-radius: 16px;

  font-size: 18px;
  font-weight: 600;
}
.EmailMarketing .menu button i {
  margin: 0 10px 0 0;
}
.EmailMarketing .menu button.active,
.EmailMarketing .menu button:hover {
  background: #35393e;
}

.EmailMarketing .section-actions {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
}
.EmailMarketing .section-actions button {
  height: 100%;
  background: rgb(147, 166, 117);
  color: white;
  padding: 15px 28px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;

  display: flex;
  align-items: center;
}
.EmailMarketing .section-actions button:hover {
  background: rgb(112, 130, 83);
}
.EmailMarketing .section-actions button i {
  margin: 0 10px 0 0;
}

.EmailMarketing .campaigns .campaign {
  background: #fff;
  padding: 30px;
  border-radius: 16px;

  display: flex;
  align-items: center;
  margin: 0 0 20px;

  position: relative;
}
.EmailMarketing .campaigns .campaign .preview {
  margin: 0 40px 0 0;
}
.EmailMarketing .campaigns .campaign p {
  margin: 10px 0;
  font-size: 20px;
}
.EmailMarketing .campaigns .campaign .audience-info {
  color: #484848;
  text-align: center;
  border-radius: 18px 0;
  position: absolute;
  bottom: 30px;
  right: 40px;
  font-size: 18px;
  font-weight: 600;
}
.EmailMarketing .campaigns .campaign .audience-info i {
  margin: 0 10px 0 0;
}

.EmailMarketing .modalCampaign {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  width: 100%;
  height: 100vh;
  background: var(--background-modal);
  backdrop-filter: blur(6px);
  z-index: -1;

  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 1s ease-in-out;
}
.EmailMarketing .modalCampaign.active {
  z-index: 9999;
  opacity: 1;
}
.EmailMarketing .modalCampaign .content {
  background: #f1f1ef;
  border-radius: 16px;
  padding: 60px;
  width: 100%;
  max-width: 800px;

  position: relative;
  transition: .4s ease-in-out;
}
.EmailMarketing .modalCampaign .content.min {
  max-width: 660px;
}
.EmailMarketing .modalCampaign .content .section {
  opacity: 0;
  height: 0;
  overflow: hidden;

  transition: 2s ease-in-out;
}
.EmailMarketing .modalCampaign .content .section.active {
  opacity: 1;
  height: auto;
}
.EmailMarketing .modalCampaign .content .head {
  display: flex;
  justify-content: space-between;
  margin: 0 0 60px;
}
.EmailMarketing .modalCampaign .content .head h1 {
  font-size: 38px;
  font-weight: 700;
  color: #333;
  line-height: 1;
  padding: 0 60px 0 0;
  max-width: 500px;
}
.EmailMarketing .modalCampaign .content .close {
  width: 50px;
  height: 50px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 30px;

  position: absolute;
  right: 80px;
  cursor: pointer!important;
  z-index: 99;
  top: 10px;
  right: 10px;
}
.EmailMarketing .modalCampaign .content .close:hover {
  background: rgba(0,0,0,.1);
}
.EmailMarketing .modalCampaign .content .body {
  margin: 0 0 40px;
}
.EmailMarketing .modalCampaign .content .section.two .body .preview {
  width: 140px;
  height: 140px;
}
.EmailMarketing .modalCampaign .content .section.two .body .preview .preview-html {
  transform: scale(.2);
}
.EmailMarketing .modalCampaign .content .section.four .body {
  display: flex;
}
.EmailMarketing .modalCampaign .content .section.four .body .preview {
  margin: 0 60px 0 0;
  width: 270px;
  height: 270px;
}
.EmailMarketing .modalCampaign .content .section.four .body .information .item {
  margin: 20px 0;
}
.EmailMarketing .modalCampaign .content .section.four .body .information .item h3 {
  font-size: 18px;
}
.EmailMarketing .modalCampaign .content .section.four .body .information .item p {
  font-size: 20px;
}
.EmailMarketing .modalCampaign .content .section.four .body .information .item span.people {
  font-size: 18px;
  font-weight: 800;
  color: #6c3abb;
}
.EmailMarketing .modalCampaign .content .section .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EmailMarketing .modalCampaign .content .section .actions.end {
  justify-content: flex-end;
}
.EmailMarketing .modalCampaign .content .section.four .actions.confirm button {
  width: auto;
  min-width: 70px;
  height: 70px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
}
.EmailMarketing .modalCampaign .content .section.four .actions.confirm button.send {
  padding: 22px 40px;
}
.EmailMarketing .modalCampaign .content .section.four .actions.confirm button.send i {
  margin: 0 20px 0 0;
}

.EmailMarketing .modalCampaign .content .body h4 {
  font-size: 20px;
}
.EmailMarketing .modalCampaign .content .body h4 span {
  font-weight: 800;
  color: rgb(92, 36, 182);
}
.EmailMarketing .modalCampaign .content .actions {
  display: flex;
  justify-content: flex-end;
}
.EmailMarketing .modalCampaign .content .actions .go {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  right: 60px;
  cursor: pointer!important;
  color: white;
  border: none;
  background: rgb(52, 202, 157);
  opacity: .8;

  transition: .4s ease-in-out;
}
.EmailMarketing .modalCampaign .content .actions .go:hover,
.EmailMarketing .modalCampaign .content .wrapper-body button.action-go:hover {
  opacity: 1;
}
.EmailMarketing .modalCampaign .content .actions .go.back {
  background: rgb(155, 178, 221);
}

.EmailMarketing .modalCampaign .content .section.two .templates {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px 40px 10px 0;
  border-radius: 20px;
}
.EmailMarketing .modalCampaign .content .section.two .templates .template {
  cursor: pointer;
  border: 4px solid transparent;
  transition: .4s ease-in-out;
}
.EmailMarketing .modalCampaign .content .section.two .templates .template:hover,
.EmailMarketing .modalCampaign .content .section.two .templates .template.active {
  border-color: rgb(92, 36, 182);
}
.EmailMarketing .modalCampaign .content .section.three .audiences {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px 40px 10px 0;
  border-radius: 20px;
}
.EmailMarketing .modalCampaign .content .section.three .audiences .audience {
  cursor: pointer;
  border: 4px solid transparent;
  transition: .4s ease-in-out;
  margin: 0 0 6px;
}
.EmailMarketing .modalCampaign .content .section.three .audiences .audience:hover,
.EmailMarketing .modalCampaign .content .section.three .audiences .audience.active {
  border-color: rgb(92, 36, 182);
}

.EmailMarketing .audiences .audience {
  background: #fff;
  padding: 30px 40px;
  border-radius: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
.EmailMarketing .audiences .audience .information p {
  margin: 0;
}
.EmailMarketing .information .status {
  font-weight: 800;
}
.EmailMarketing .information .status.createdAt {
  color: rgb(118, 143, 189);
}
.EmailMarketing .information .status.updatedAt {
  color: rgb(88, 165, 175);
}
.EmailMarketing .audiences .audience .audience-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.EmailMarketing .audiences .audience .audience-info .leads-number {
  font-size: 30px;
  font-weight: 600;
}
.EmailMarketing .audiences .audience .audience-info .leads-info {
  font-size: 16px;
  font-weight: 600;
}
.EmailMarketing .audiences .audience .actions {
  margin: 0 0 0 40px;
}
.EmailMarketing .audiences .audience .actions button {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: rgb(155, 178, 221);
  color: white;
  border: none;
  margin: 0 0 0 6px;  

  font-size: 20px;
  cursor: pointer;
  opacity: .9;

  transition: .4s ease-in-out;
}
.EmailMarketing .audiences .audience .actions button:hover {
  opacity: 1;
}
.EmailMarketing .audiences .audience .actions button:hover i {
  font-size: 22px;
}
.EmailMarketing .audiences .audience .actions button.edit-audience {
  background: rgb(88, 165, 175);
}

.EmailMarketing .audiences .modalAudience {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px;
  height: 100vh;
  background: var(--background-modal);
  backdrop-filter: blur(6px);

  display: none;
  justify-content: center;
  align-items: center;
}
.EmailMarketing .audiences .modalAudience.active {
  display: flex;
}
.EmailMarketing .audiences .modalAudience .content {
  background: white;
  border-radius: 16px;
  padding: 60px;
  width: 100%;
  max-width: 900px;

  position: relative;
  transition: .4s ease-in-out;
}
.EmailMarketing .audiences .modalAudience .content.min {
  max-width: 660px;
}
.EmailMarketing .audiences .modalAudience .content .close {
  width: 50px;
  height: 50px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 30px;

  position: absolute;
  right: 60px;
  cursor: pointer!important;
  z-index: 99;
}
.EmailMarketing .audiences .modalAudience .content .close:hover {
  background: rgba(0,0,0,.1);
}
.EmailMarketing .audiences .modalAudience .list-items {
  margin: 30px 0;
}
.EmailMarketing .audiences .modalAudience .list-items label {
  display: flex;
  align-items: center;
}
.EmailMarketing .audiences .modalAudience .list-items .new {
  padding: 3px 8px;
  background: var(--green-two);
  font-size: 12px;
  font-weight: 800;
  color: white;
  border-radius: 6px;
  margin: 0 7px;
}
.EmailMarketing .audiences .modalAudience .list-items p {
  font-size: 18px;
  margin: 0 0 20px;
}
.EmailMarketing .audiences .modalAudience .list-items .item {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  cursor: pointer;
}
.EmailMarketing .audiences .modalAudience .list-items .item .input-radio {
  width: 24px;
  height: 24px;
  border: 3px solid rgb(130 153 195);
  border-radius: 100%;
  transition: .4s ease-in-out;
  position: relative;
}
.EmailMarketing .audiences .modalAudience .list-items .item .input-radio.active::after {
  content: "";
  width: 14px;
  height: 14px;
  background: rgb(130 153 195);
  border-radius: 100%;
  
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
}
.EmailMarketing .audiences .modalAudience .list-items .item label {
  margin: 2px 0 0;
  font-size: 18px;
  font-weight: 800;
  opacity: .8;
  cursor: pointer;
}
.EmailMarketing .audiences .modalAudience .selectLeads {
  margin: 0 0 40px;
  max-height: 300px;
  overflow-y: scroll;
}
.EmailMarketing .audiences .modalAudience .selectLeads .item {
  margin: 0 0 6px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
}
.EmailMarketing .audiences .modalAudience .selectLeads .item input {
  width: 26px;
  height: 26px;
  border-color: #209d77;
}
.EmailMarketing .audiences .modalAudience .selectLeads .item input:after {
  top: 4px;
  left: 7px;
}
.EmailMarketing .audiences .modalAudience .selectLeads .item input:checked {
  background: #209d77!important;
}
.EmailMarketing .audiences .modalAudience .selectLeads .item label {
  margin: 2px 0 0;
  font-size: 18px;
  font-weight: 800;
  opacity: .8;
}
.EmailMarketing .audiences .modalAudience button {
  float: right;
}
.EmailMarketing .audiences .modalAudience .actions-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.EmailMarketing .audiences .modalAudience .actions-edit button {
  float: initial;
  margin: 0;
}
.EmailMarketing button.remove {
  padding: 10px;
  background-color: transparent;
  color: rgba(238, 35, 20);
  font-weight: 800;
  opacity: .5;
  border: none;

  transition: .4s ease-in-out;
  position: relative;
  cursor: pointer;
}
.EmailMarketing .templates button.remove {
  margin: 0 20px;
  font-size: 16px;
}
.EmailMarketing button.remove:hover {
  opacity: .7;
  background-color: transparent;
}
.EmailMarketing button.remove::after {
  content: "";
  width: 0px;
  height: 2px;
  background: rgba(238, 35, 20);

  position: absolute;
  bottom: 2px;
  left: 50%;
  border-radius: 10px;
  transition: .4s ease-in-out;
}
.EmailMarketing button.remove:hover::after {
  width: 90%;
  left: 5%;
}

.EmailMarketing .audiences .modalAudience .deleting h2 {
  font-size: 30px;
  font-weight: 300;
}
.EmailMarketing .audiences .modalAudience .deleting h2 span {
  font-weight: 800;
}
.EmailMarketing .audiences .modalAudience .deleting .actions {
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 0;
}
.EmailMarketing .audiences .modalAudience .deleting .actions button.cancel {
  background: rgb(202 52 52);
}

.EmailMarketing .templates .template {
  background: #fff;
  padding: 30px 30px 30px 40px;
  border-radius: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}
.EmailMarketing .preview {
  height: 200px;
  width: 200px;
  margin: 0 0 0 40px;

  overflow: hidden;
  position: relative;
  
  background: rgb(231, 231, 231);
  border-radius: 16px;
  display: flex;
  justify-content: center;
}
.EmailMarketing .preview .preview-html {
  width: 100%;
  transform: scale(.3);
  max-height: 0;
  min-width: 600px;
}
.EmailMarketing .preview .preview-html-overlay {
  width: 100%;
  height: 100%;
  background: transparent;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.EmailMarketing .templates .template .actions.disabled > button.edit {
  opacity: .6;
}
.EmailMarketing .templates .template .actions button.edit {
  padding: 14px 20px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  margin: 20px 0 0;
  background: rgb(147, 166, 117);
  color: white;

  cursor: pointer;
}
.EmailMarketing .templates .template .actions button.edit:hover {
  background: rgb(112, 130, 83);
}