.Reports h2 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 500;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}
.Reports h2 i {
  margin: 0 20px 0 0;
  font-size: 30px;
}
.Reports .report-section {
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  padding: 0 0 60px;
  position: relative;
  overflow: hidden;
}
.Reports .report-section > h3 {
  margin: 0 10px 6px;
  text-align: center;
  
  font-size: 26px;
}
.Reports .report-section > h2 {
  margin: 0 10px 30px;
  text-align: center;
  font-weight: 400;
  
  font-size: 20px;
  display: block;
}
.Reports .report-section > h2 span {
  font-weight: bold;
  color: var(--green-two);
}
.Reports .report-search {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 5px;
  align-items: flex-end;
}
.Reports .report-search button {
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  background-color: var(--green-two);
  transition: .4s ease;
  height: 58px;
}
.Reports .report-search button:hover {
  background-color: var(--green-three);
}
.Reports .input-search {
  display: flex;
  flex-direction: column;
}
.Reports .input-search label {
  padding: 8px 8px 4px;
  font-size: 16px;
  font-weight: 800;
}
.Reports .input-search input {
  width: 100%;
  padding: 14px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
}

.Reports .report-details {
  margin: 10px 0 0;
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-gap: 5px;
}
.report-details.products {
  display: flex;
}
.Reports .report-details > div {
  background-color: rgba(255,255,255,.6);
  padding: 20px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
}
.Reports .report-details.products > div {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}
.Reports .report-details .totalAmount {
  justify-content: center;
  align-items: center;
}
.Reports .report-details label {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
  margin: 0 0 6px;
}
.Reports .report-details .totalAmount label {
  font-size: 20px;
  margin: 0;
}
.Reports .report-details span {
  font-size: 18px;
  font-weight: 800;
}
.Reports .report-details .totalAmount span {
  font-size: 24px;
}
.Reports .report-details .paymentMethods {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.Reports .report-details .paymentMethods .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Reports .report-details .paymentMethods .item .icon {
  margin: 0 0 20px;
  font-size: 35px;
  color: var(--green-oneother);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  line-height: 0;
}

.Reports .report-details.products .product-list {
  width: 100%;
}
.Reports .report-details.products .product-list .product-item .item-data {
  cursor: pointer;
  background-color: rgba(255,255,255,.6);
  padding: 12px 12px 12px 30px;
  border-radius: 12px;
  margin: 0 0 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Reports .report-details.products .product-list .product-item .item-data .button-view {
  width: 40px;
  height: 40px;
  background: #c6cacd;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  fill: rgba(0,0,0,.5);
}
.Reports .report-details.products .product-list .product-item .item-data label {
  min-width: 40px;
  margin: 0;
  padding: 0 20px 0 0;
  
  font-size: 20px;
  font-weight: 800;
}
.Reports .report-details.products .product-list .product-item .item-data span {
  margin: 0;
  
  font-size: 20px;
  font-weight: 600;
}
.Reports .report-details.products .product-list .product-item .item-details {
  margin: 0 0 10px;
}
.Reports .report-details.products .product-list .product-item .item-details h3 {
  font-size: 20px;
  font-weight: 800;
  margin: 20px 30px;
  text-align: center;
}
.Reports .report-details.products .product-list .product-item .item-details .order-item {
  width: 100%;
  display: flex;
  justify-content: space-between;

  cursor: pointer;
  background-color: rgba(255,255,255,.6);
  padding: 14px 14px 14px 30px;
  border-radius: 12px;
  margin: 0 0 5px;
  display: flex;
  align-items: center;
}
.Reports .report-details.products .product-list .product-item .item-details .order-item > div {
  display: flex;
  flex-direction: column;
}
.Reports .report-details.products .product-list .product-item .item-details .order-item .view-order {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: .4s;
  background-color: rgb(105, 170, 223);
  color: white;
}

.Reports button.generatePdf {
  padding: 20px;
  font-size: 20px;
  border: none;
  border-radius: 12px;
  background: #3584d3;
  color: white;
  font-weight: 500;
  margin: 40px auto;
  max-width: 400px;
  width: 100%;
  cursor: pointer;
  opacity: .8;
  transition: .4s ease-in-out;
}
.Reports button.generatePdf:hover {
  opacity: 1;
}
.Reports .notFound {
  margin: 20px auto 0;
  background: #a5a5a5;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
}