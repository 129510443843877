.newOrder h1 {
  font-size: 40px;
  letter-spacing: -1px;
  color: var(--gray-dark);
  font-weight: 700;
  margin: 40px 0;
}
.newOrder h2 {
  font-size: 24px;
  color: var(--gray-dark);
  font-weight: 700;
  margin: 0 0 16px 5px;
}
.newOrder h3 {
  font-size: 24px;
  color: var(--gray-dark);
  font-weight: 700;
}

.form .clientSelection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.form .clientSelection button {
  padding: 20px;
  font-size: 20px;
  border: none;
  border-radius: 12px;
  background: #708253;
  color: white;
  opacity: .8;
  cursor: pointer;
}
.form .clientSelection button:hover {
  opacity: 1;
}
.form .sectionClient .newClient {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}
.form .sectionClient .newClient h2,
.form .sectionClient .newClient .item {
  width: 100%;
}
.form .sectionClient .newClient .added button {
  border: none!important;
  background: transparent!important;
  color: var(--green-oneother);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.form .sectionClient .newClient .added button:hover {
  text-decoration: underline!important;
}
.form .sectionClient .newClient .inputSearchItems {
  width: 100%;
  margin: 0 0 10px;
  position: relative;
}
.form .clientConnected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form .clientConnected h4 {
  cursor: pointer;
}

.form .formSection {
  margin: 40px 0;
  border-top: 1px solid rgba(0,0,0,.1);
  padding: 40px 0 0;
}
.flex-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.align-right {
  text-align: right;
}
.form .formSection .details .item {
  margin: 20px 0;
}
.form .formSection .details h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: var(--gray-five);
  margin: 0;
  margin: 0 0 7px;
}
.form .formSection .details span {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.inputSearchItems input {
  width: 100%;
  padding: 14px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
}
.inputSearchItems input:focus,
.inputSearchItems.selected input {
  color: var(--green-oneother);
  border-color: var(--green-one);
  font-size: 19px;
  font-weight: 600;
  transition: all .8s;
}
.inputSearchItems.selected input {
  border: none;
  background-color: transparent;
  padding: 0 0 14px;
}

.inputSearchItems .result-search {
  width: 70%;
  margin: 4px 0 0 30%;
  background: var(--white);
  border-radius: 10px;
  max-height: 296px;
  overflow-y: auto;
  position: absolute;
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
  box-shadow: 0 20px 28px -10px rgb(0 0 0 / 20%);
  color: var(--gray-dark);
  z-index: 9999;
  border: 4px solid #efefef;
}
.inputSearchItems .item {
  padding: 20px;
  font-size: 18px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  cursor: pointer;
}
.inputSearchItems .item:last-child {
  border: none;
}
.inputSearchItems .item:hover {
  background: var(--green-one);
  color: #fff;
  font-weight: 700;
  border: none
}

.inputSearchProducts {
  margin: 40px 0;
  position: relative;
}
.inputSearchProducts input {
  width: 100%;
  padding: 14px 20px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
}
.inputSearchProducts input:focus,
.inputSearchProducts.selected input {
  color: var(--green-oneother);
  border-color: var(--green-one);
  font-size: 19px;
  font-weight: 600;
  transition: all .8s;
}
.inputSearchProducts.selected input {
  border: none;
  background-color: transparent;
  padding: 0 0 14px;
}
.inputSearchProducts .result-search {
  width: 70%;
  margin: 4px 0 0 30%;
  background: var(--white);
  border-radius: 10px;
  max-height: 296px;
  overflow-y: auto;
  position: absolute;
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
  box-shadow: 0 20px 28px -10px rgb(0 0 0 / 20%);
  color: var(--gray-dark);
  z-index: 9999;
  border: 4px solid #efefef;
}
.inputSearchProducts .result-search .item {
  padding: 20px;
  font-size: 18px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  cursor: pointer;
}
.inputSearchProducts .result-search .item:last-child {
  border: none;
}
.inputSearchProducts .result-search .item:hover {
  background: var(--green-one);
  color: #fff;
  font-weight: 700;
  border: none
}
.inputSearchProducts .product-items .item {
  padding: 4px;
  font-size: 18px;
  cursor: pointer;
  background: #dfe0e2;
  margin: 5px 0;
  border-radius: 10px;
  border: 2px solid #fff;
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
  position: relative;
  display: flex;
  align-items: center;
}
.inputSearchProducts .product-items .item .quantity {
  position: absolute;
  top: -5px;
  left: -5px;
  background: var(--green-three);
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
}
.inputSearchProducts .product-items .item .image {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  background: #ececec;
  border-radius: 6px;
  overflow: hidden;
}
.inputSearchProducts .product-items .item .image img {
  width: 44px;
  height: 44px;
}
.inputSearchProducts .product-items .item button.remove {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.inputSearchProducts .product-items .item button.remove span {
  width: 25px;
  height: 5px;
  background: #fff;
  position: absolute;
  border-radius: 50px;
  transition: all .8s;
}
.inputSearchProducts .product-items .item button.remove span:first-child { transform: rotate(45deg); }
.inputSearchProducts .product-items .item button.remove span:last-child { transform: rotate(-45deg); }

.inputSearchProducts .product-items .item:hover {
  color: #d26868;
  border-color: #ff9494;
  transition: all .8s;
}
.inputSearchProducts .product-items .item:hover button.remove span,
.inputSearchProducts .product-items .item:hover .quantity {
  background-color: #d26868;
  transition: all .8s;
}

.inputSearchProducts .categories-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin: 0 0 10px;
}
.inputSearchProducts .categories-items .item {
  padding: 4px 20px;
  font-size: 18px;
  cursor: pointer;
  background: linear-gradient(
45deg, #e9e9e9, transparent);
  border-radius: 10px;
  border: 2px solid #fff;
  transition: all .2s cubic-bezier(.71,0,.33,1.56) 0ms;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
  color: #61686e;
  font-weight: 600;
}
.inputSearchProducts .categories-items .item button.remove {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.inputSearchProducts .categories-items .item button.remove span {
  width: 25px;
  height: 5px;
  background: #fff;
  position: absolute;
  border-radius: 50px;
  transition: all .8s;
}
.inputSearchProducts .categories-items .item button.remove span:first-child { transform: rotate(45deg); }
.inputSearchProducts .categories-items .item button.remove span:last-child { transform: rotate(-45deg); }

.inputSearchProducts .categories-items .item:hover {
  color: #d26868;
  border-color: #ff9494;
  transition: all .8s;
}
.inputSearchProducts .categories-items .item:hover button.remove span,
.inputSearchProducts .categories-items .item:hover .quantity {
  background-color: #d26868;
  transition: all .8s;
}

.react-datepicker {
  min-width: 395px;
  max-width: 395px;
  width: 100%;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100%!important;
}

.newOrder .content .success h1 {
  margin: 40px 0 10px; 
}
.newOrder .content .success .action {
  margin: 20px 0;
  display: flex;
}
.newOrder .content .success .action a {
  padding: 14px 20px;
  background: var(--green-oneother);
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  border-radius: 8px;
}
.newOrder .content .success .action a:hover {
  background: var(--green-one);
  transition: all .2s;
}