.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.3);
  z-index: 99999999;
}
.modal .modal-content {
  position: relative;
  width: 100%;
  max-width: 700px;
  height: 200px;
}
.modal .overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.);
  top: 0;
  left: 0;
  position: absolute;
}
.modal .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--gray-darkother);
  border-radius: 10px;
  font-size: 14px;
  width: 100%;
  max-width: 700px;
  background: rgb(255 255 255 / 80%);
  padding: 40px;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
}
.modal .content h1 {
  color: var(--gray-darkother);
  font-size: 22px;
}
.modal .content .actions {
  margin: 40px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.modal .content .actions button {
  background: var(--gray-dark);
  width: 100px;
  height: 50px;
  font-size: 16px;
  color: #fff;
  font-weight: 800;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.modal .content .actions button.cancel {
  background: var(--lilacother);
}
.modal .content .actions button.confirm {
  background: var(--green-oneother);
}